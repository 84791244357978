<template>
  <Teleport :to="appendTo">
    <aside
      v-show="modelValue"
      ref="modal"
      class="fixed bottom-0 left-0 z-50 flex max-h-svh min-h-svh w-svw flex-col transition-all duration-75"
      :class="{ 'justify-end': position === 'bottom' }"
    >
      <div
        v-show="modelValue"
        class="size-screen fixed inset-0"
        :class="overlayColor"
        @click="closeModal"
      />
      <Component
        :is="transitionComponent"
        :offset="transitionAxisMap[position]"
        :duration="500"
        @enter="onTransitionEnter"
        @leave="onTransitionLeave"
      >
        <template v-if="modelValue">
          <div
            v-bind="{ ...$attrs }"
            class="relative transition-transform will-change-transform"
            :class="{ 'mx-auto w-fit': fit }"
            :style="{ opacity: modalOpacity, transform: `translateY(${modalTranslate}px)` }"
          >
            <SwipeDownCloser
              v-if="swipeClose && modalHeight"
              v-model:container-translate="modalTranslate"
              v-model:container-opacity="modalOpacity"
              :container-height="modalHeight"
              class="absolute -top-5 z-20 -mb-0.5 pb-3 pt-7"
              no-translate
              :close-threshold="10"
              @close="closeModal"
              @swipe-up="showContainer"
            />
            <header v-if="$slots.header" class="relative flex items-center">
              <UiButton
                icon
                transparent
                class="!absolute -top-4 z-30 size-10 text-text-main"
                :class="[closePosition === 'right' ? '-right-4' : '-left-4', closeClasses]"
                @click="closeModal"
              >
                <UiIcon name="x-mark" class="size-5" />
              </UiButton>
              <slot name="header" />
            </header>
            <slot />
          </div>
        </template>
      </Component>
    </aside>
  </Teleport>
</template>

<script lang="ts" setup>
import { TransitionFade, TransitionSlide } from '@morev/vue-transitions'
import throttle from 'lodash/throttle.js'
import type { Nullable, Undefinable } from 'ts-helpers'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useSwipeDownCloser } from '../composables'
import { getMilliseconds, lockScroll, unlockScroll } from '../lib'
import SwipeDownCloser from './SwipeDownCloser.vue'
import UiButton from './UiButton.vue'
import UiIcon from './UiIcon.vue'

type PropType = {
  modelValue: boolean
  appendTo?: string
  position?: 'top' | 'bottom' | 'center' | 'left' | 'right'
  closePosition?: 'left' | 'right'
  closeClasses?: string
  swipeClose?: boolean
  overlayColor?: string
  fit?: boolean
  saveScrollLock?: boolean
}

type EmitType = {
  (e: 'update:modelValue', value: boolean): void
}

const props = withDefaults(defineProps<PropType>(), {
  modal: true,
  position: 'center',
  overlay: true,
  closePosition: 'right',
  closeClasses: '',
  swipeClose: false,
  appendTo: 'body',
  overlayColor: 'bg-overlay',
  fit: false,
  saveScrollLock: false
})

const emit = defineEmits<EmitType>()

defineOptions({
  inheritAttrs: false
})

const INIT_TIMEOUT = getMilliseconds.inSeconds(0.2)

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      lockScroll()
      setTimeout(() => setModalHeight(), INIT_TIMEOUT)

      return
    }

    if (!props.saveScrollLock) unlockScroll()
  }
)

const showContent = ref(false)
onMounted(() => setTimeout(() => (showContent.value = true), INIT_TIMEOUT))
onBeforeUnmount(() => (showContent.value = false))

// Transitions
const transitionComponent = computed(() =>
  props.position === 'center' ? TransitionFade : TransitionSlide
)

type TransitionSlideOffsetValue = [number | string, number | string]

const transitionAxisMap: Record<
  Partial<NonNullable<PropType['position']>>,
  Undefinable<TransitionSlideOffsetValue>
> = {
  top: [0, -1000],
  bottom: [0, 1000],
  left: [-1000, 0],
  right: [1000, 0],
  center: undefined
}

const closeModal = () => {
  emit('update:modelValue', false)
  showContainer()
}

// Swipe Down
const modal = ref<Nullable<HTMLElement>>(null)
const modalHeight = ref(0)
const {
  containerTranslate: modalTranslate,
  containerOpacity: modalOpacity,
  showContainer
} = useSwipeDownCloser()

const setModalHeight = () => (modalHeight.value = modal.value!.offsetHeight)

const onResizeHandler = throttle(function () {
  if (!modal.value) return

  modal.value.style.minHeight = window.innerHeight + 'px'
}, 50)
const onEscapeHandler = (e: KeyboardEvent) => {
  e.key === 'Escape' && closeModal()
}

const onTransitionEnter = () => {
  onResizeHandler()

  window.addEventListener('resize', onResizeHandler)
  window.addEventListener('keydown', onEscapeHandler)
}

const onTransitionLeave = () => {
  window.removeEventListener('resize', onResizeHandler)
  window.removeEventListener('keydown', onEscapeHandler)
}
</script>
