<template>
  <Teleport :to="appendTo">
    <TransitionFade @enter="onEnter">
      <div
        v-if="visible"
        ref="container"
        v-bind="{ ...$attrs }"
        class="absolute z-[900] rounded-xl border border-secondary bg-primary shadow-poster_primary"
        :class="[{ '-translate-y-3': isTopPopover }, { 'translate-y-3': isBottomPopover }]"
        :style="{
          left: `${containerStyles.left}px`,
          top: `${containerStyles.top}px`,
          transformOrigin: containerStyles.transformOrigin
        }"
      >
        <slot />
      </div>
    </TransitionFade>
  </Teleport>
</template>

<script lang="ts" setup>
import { TransitionFade } from '@morev/vue-transitions'
import { onClickOutside } from '@vueuse/core'
import type { Undefinable } from 'ts-helpers'
import { computed, reactive, ref } from 'vue'
import { getElementAbsolutePosition } from '../lib'

type PropType = {
  appendTo?: string
  dontHideOnOutsideClick?: boolean
  triangleClasses?: string
  alwaysVisible?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  appendTo: 'body',
  dontHideOnOutsideClick: false,
  triangleClasses: 'text-[var(--backgroundColor-primary)]',
  alwaysVisible: false
})

defineOptions({
  inheritAttrs: false
})

const visible = ref(false)
const container = ref<Undefinable<HTMLElement>>(undefined)
const target = ref<Undefinable<HTMLElement>>(undefined)

type ContainerStyles = {
  top: number
  left: number
  transformOrigin: 'top' | 'bottom'
}
type ContainerPosition = {
  positionY: 'bottom' | 'top'
  positionX: 'left' | 'right'
}

const containerStyles = reactive<ContainerStyles>({
  top: 0,
  left: 0,
  transformOrigin: 'bottom'
})
const containerPosition = reactive<ContainerPosition>({
  positionY: 'bottom',
  positionX: 'left'
})

const setContainerStyles = (styles: ContainerStyles) => {
  containerStyles.top = styles.top
  containerStyles.left = styles.left
  containerStyles.transformOrigin = styles.transformOrigin
}
const setContainerPosition = (position: ContainerPosition) => {
  containerPosition.positionX = position.positionX
  containerPosition.positionY = position.positionY
}

const isTopPopover = computed(() => containerPosition.positionY === 'top')
const isBottomPopover = computed(() => containerPosition.positionY === 'bottom')
// const isRightPopover = computed(() => containerPosition.positionX === 'right')
// const isLeftPopover = computed(() => containerPosition.positionX === 'left')

const onEnter = () => {
  const calculatedPosition = getElementAbsolutePosition(container.value, target.value)

  calculatedPosition && setContainerStyles(calculatedPosition as ContainerStyles)
  calculatedPosition && setContainerPosition(calculatedPosition as ContainerPosition)
  !props.dontHideOnOutsideClick && onClickOutside(container.value, () => hide())
}

const show = (event: MouseEvent) => {
  if (props.alwaysVisible) return

  visible.value = true
  event.target && (target.value = event.target as HTMLElement)
}

const hide = () => {
  if (props.alwaysVisible) return

  visible.value = false
  target.value = undefined
  container.value = undefined
}

const toggle = (event: MouseEvent) => {
  if (props.alwaysVisible) return

  visible.value ? hide() : show(event)
}

defineExpose({
  show,
  hide,
  toggle
})
</script>
