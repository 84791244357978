export const getScrollBarWidth = () => {
  const savedValue = localStorage.getItem('clientSidebarWidth')

  if (!savedValue) {
    // https://learn.javascript.ru/task/scrollbar-width
    const div = document.createElement('div')

    div.style.overflowY = 'scroll'
    div.style.width = '50px'
    div.style.height = '50px'

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div)
    const scrollWidth = div.offsetWidth - div.clientWidth

    div.remove()
    localStorage.setItem('clientSidebarWidth', scrollWidth.toString())

    return scrollWidth
  }

  return +savedValue
}

const setBodyStyles = (styles: string) => {
  document.body.style.cssText = styles
}

export const lockScroll = () => {
  const scrollBarOffset = getScrollBarWidth()
  const styles = `
    height: 100%;
    overflow: hidden;
    padding-right: ${scrollBarOffset}px;
  `
  setBodyStyles(styles)
}

export const unlockScroll = () => {
  setBodyStyles('')
}
