import { withQuery } from 'ufo'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

/**
 * добавляет возможность отменить какое либо действие нативной навигацией
 * @param queryName - имя query-параметра
 * @param popstateHandler - метод, который вызывается при возврате назад
 */
export const useQueryParamsOpenState = (queryName: string, popstateHandler: () => void) => {
  const route = useRoute()
  const router = useRouter()

  const setParamInRouteQuery = (queryValue: string, mode: 'push' | 'replace' = 'push') => {
    const url = withQuery(route.fullPath, { [queryName]: queryValue })
    mode === 'push' ? router.push(url) : router.replace(url)
  }

  const popstateListenerHandler = () => {
    popstateHandler()
  }

  onMounted(() => {
    window.addEventListener('popstate', popstateListenerHandler)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('popstate', popstateListenerHandler)
  })

  return {
    setParamInRouteQuery
  }
}
